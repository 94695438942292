import { createContext } from 'react';
import { CreateReviewForClinicResponse, genderTypes, reviewPromptTypes } from 'patient-api-lib';
import IconLeft from './assets/images/icon-tooth-sidebar.svg';
import { filteringScreens } from './utils/filteringSteps';
import {
  AppConfig, ConfigFeatures, OverviewData,
} from './types/Models';
import { getReviewIDFromURL } from './helpers/getReviewIDFromURL';
import { REGEX_PATTERNS } from './utils/RegexUtils';
import i18NService from './service/I18NService';
import { REVIEW_PROMPT_NAME } from './constants';

const [reviewID] = getReviewIDFromURL();

export const overviewData = (): OverviewData => ({
  overviews: [
    {
      title: i18NService.getTranslation('overview1.title'),
      description: i18NService.getTranslation('overview1.description'),
      illustration: IconLeft,
      steps: [
        {
          id: 'LEAVE_REVIEW',
          title: i18NService.getTranslation('overview1.step2.title'),
          description: i18NService.getTranslation('overview1.step2.description'),
          state: 'active',
        },
      ],
    },
    {
      title: i18NService.getTranslation('overview2.title'),
      description: i18NService.getTranslation('overview2.description'),
      illustration: IconLeft,
      steps: [
        {
          id: 'LEAVE_REVIEW',
          title: i18NService.getTranslation('overview2.step2.title'),
          description: i18NService.getTranslation('overview2.step2.description'),
          state: 'active',
        },
      ],
    },
    {
      title: i18NService.getTranslation('overview3.title'),
      description: i18NService.getTranslation('overview3.description'),
      illustration: IconLeft,
      steps: [
        {
          id: 'LEAVE_REVIEW',
          title: i18NService.getTranslation('overview3.step2.title'),
          description: i18NService.getTranslation('overview3.step2.description'),
          state: 'active',
        },
      ],
    },
    {
      title: i18NService.getTranslation('overview4.title'),
      description: i18NService.getTranslation('overview4.description'),
      illustration: IconLeft,
      steps: [
        {
          id: 'LEAVE_REVIEW',
          title: i18NService.getTranslation('overview4.step2.title'),
          description: i18NService.getTranslation('overview4.step2.description'),
          state: 'active',
        },
      ],
    },
  ],
});

export const getConfig = (
  features?: Partial<ConfigFeatures>,
  currentChapter: number = 0,
  submitResponse?: CreateReviewForClinicResponse | null,
): AppConfig => {
  const { CONFIRM_MODAL_ON, GOOGLE_REVIEW_BUTTON, TRUSTPILOT_REVIEW_BUTTON } = features || {};
  const sendedReview = submitResponse && submitResponse.getReview();
  const isGoodReview = sendedReview?.getTotalScore() && sendedReview?.getTotalScore() >= 4;
  const reviewPrompt = sendedReview && submitResponse?.getReviewPrompt();
  const reviewPromptType = (reviewPrompt && reviewPrompt.getType()) || reviewPromptTypes.google;

  const isShowReviewPrompt = (GOOGLE_REVIEW_BUTTON || TRUSTPILOT_REVIEW_BUTTON) && isGoodReview && reviewPrompt;

  const ConfirmSubmitReview = CONFIRM_MODAL_ON
    ? {
      title: i18NService.getTranslation('chapter1.screen2.confirmScreen.title'),
      content: '',
    }
    : null;

  const configData: AppConfig = {
    progress: 0,
    currentChapter: 0,
    currentScreen: 0,
    chapters: [
      {
        screens: [
          {
            title: '',
            id: 'SELECTOR_CLINIC',
            type: 'selector',
            canSelectMultiple: false,
            formTitle: i18NService.getTranslation('chapter1.screen1.formTitle'),
            formDescription: i18NService.getTranslation('chapter1.screen1.formDescription'),
            submittable: false,
            noProgress: !!reviewID,
            data: {
              answers: [],
            },
          },
          {
            title: '',
            type: 'question',
            id: 'LEAVE_REVIEW',
            canSelectMultiple: true,
            formTitle: i18NService.getTranslation('chapter1.screen2.formTitle'),
            formDescription: i18NService.getTranslation('chapter1.screen2.formDescription'),
            submitAction: reviewID ? 'SEND_REVIEW_FOR_BOOKING' : undefined,
            submittable: false,
            noProgress: !!reviewID,
            confirmScreen: ConfirmSubmitReview,
            btnConfig: {
              children: i18NService.getTranslation('chapter1.screen2.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  dataLabel: 'rating',
                  type: 'rating',
                  text: '',
                  selected: false,
                  inputTitle: i18NService.getTranslation('chapter1.screen2.data.answers.0.inputTitle'),
                  inputValue: '',
                  placeholder: undefined,
                  testId: 'rating',
                },
                {
                  id: 2,
                  dataLabel: 'comments',
                  type: 'text',
                  text: '',
                  selected: false,
                  inputTitle: undefined,
                  inputValue: '',
                  placeholder: i18NService.getTranslation('chapter1.screen2.data.answers.1.placeholder'),
                  testId: 'reviewComments',
                },
              ],
            },
          },
          {
            title: '',
            type: 'question',
            id: 'USER_DETAILS',
            canSelectMultiple: false,
            formTitle: i18NService.getTranslation('chapter1.screen3.formTitle'),
            submitAction: 'SEND_REVIEW_FOR_CLINIC',
            formDescription: null,
            submittable: false,
            noProgress: !!reviewID,
            btnConfig: {
              children: i18NService.getTranslation('chapter1.screen3.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  dataLabel: 'name',
                  type: 'text-input',
                  inputOptions: {
                    type: 'text',
                    mask: '',
                    required: true,
                  },
                  text: '',
                  selected: false,
                  inputTitle: i18NService.getTranslation('chapter1.screen3.data.answers.0.inputTitle'),
                  inputValue: '',
                  placeholder: i18NService.getTranslation('chapter1.screen3.data.answers.0.placeholder'),
                  testId: 'nameInput',
                },
                {
                  id: 2,
                  dataLabel: 'age',
                  type: 'text-input',
                  inputOptions: {
                    type: 'tel',
                    inputMode: 'numeric',
                    alwaysShowMask: false,
                    maskPlaceholder: '',
                    mask: '999',
                    required: true,
                  },
                  text: '',
                  selected: false,
                  inputTitle: i18NService.getTranslation('chapter1.screen3.data.answers.1.inputTitle'),
                  inputValue: '',
                  placeholder: i18NService.getTranslation('chapter1.screen3.data.answers.1.placeholder'),
                  testId: 'ageInput',
                },
                {
                  id: 3,
                  dataLabel: 'phone',
                  type: 'text-input',
                  inputOptions: {
                    type: 'tel',
                    inputMode: 'tel',
                    mask: '+46(0) 99 999 99 99',
                    required: true,
                    errorMessage: i18NService.getTranslation('chapter1.screen3.data.answers.2.inputOptions.errorMessage'),
                  },
                  text: '',
                  selected: false,
                  inputTitle: i18NService.getTranslation('chapter1.screen3.data.answers.2.inputTitle'),
                  inputValue: '',
                  placeholder: i18NService.getTranslation('chapter1.screen3.data.answers.2.placeholder'),
                  testId: 'phoneInput',
                },
                {
                  id: 4,
                  dataLabel: 'email',
                  type: 'text-input',
                  inputOptions: {
                    type: 'email',
                    inputMode: 'email',
                    testValue: REGEX_PATTERNS.email,
                    mask: '',
                    required: true,
                    errorMessage: i18NService.getTranslation('chapter1.screen3.data.answers.3.inputOptions.errorMessage'),
                  },
                  text: '',
                  selected: false,
                  inputTitle: i18NService.getTranslation('chapter1.screen3.data.answers.3.inputTitle'),
                  inputValue: '',
                  placeholder: i18NService.getTranslation('chapter1.screen3.data.answers.3.placeholder'),
                  testId: 'emailInput',
                },
                {
                  id: 5,
                  dataLabel: 'gender',
                  type: 'radio',
                  text: '',
                  selected: false,
                  inputTitle: i18NService.getTranslation('chapter1.screen3.data.answers.4.inputTitle'),
                  inputValue: '',
                  directionAnswers: 'row',
                  testId: 'genderInput',
                  answers: [
                    {
                      label: i18NService.getTranslation('chapter1.screen3.data.answers.4.answers.0.label'),
                      value: genderTypes.male,
                      selected: false,
                      testId: 'gender-male',
                    },
                    {
                      label: i18NService.getTranslation('chapter1.screen3.data.answers.4.answers.1.label'),
                      value: genderTypes.female,
                      selected: false,
                      testId: 'gender-female',
                    },
                    {
                      label: i18NService.getTranslation('chapter1.screen3.data.answers.4.answers.2.label'),
                      value: genderTypes.unspecified,
                      selected: false,
                      testId: 'gender-unspecified',
                    },
                  ],
                },
              ],
            },
          },
          {
            title: i18NService.getTranslation('chapter1.screen4.title'),
            formDescription: isShowReviewPrompt
              ? i18NService.getTranslation('chapter1.screen4.formDescriptionReview', {
                platform: REVIEW_PROMPT_NAME[reviewPromptType] || '',
              })
              : i18NService.getTranslation('chapter1.screen4.formDescription'),
            formPrescription: i18NService.getTranslation('chapter1.screen4.formPrescriptionReview'),
            id: 'SUCCESS',
            type: 'confirmation',
            noProgress: true,
            formTitle: isShowReviewPrompt
              ? i18NService.getTranslation('chapter1.screen4.reviewPromptTitle')
              : i18NService.getTranslation('chapter1.screen4.formTitle'),
            btnConfig: {
              children: i18NService.getTranslation('chapter1.screen4.btnConfig.children'),
              type: 'default',
            },
            submittable: true,
          },
        ],
      },
    ],
  };

  return {
    ...configData,
    chapters: [{ screens: filteringScreens(configData.chapters[currentChapter].screens) }],
  };
};

export interface ConfigContextTypes extends AppConfig {
  errorCode: string;
  submitResponse: CreateReviewForClinicResponse | null;
  errorObject: any;
  screensLength: number;
  setErrorObject: any;
  setCurrentChapter: Function;
  setCurrentScreen: Function;
  setErrorCode: Function;
  showConfirmModal: boolean;
  setProgress: Function;
  setShowConfirmModal: Function;
  updateScreenData: Function;
  screenLoader: boolean;
  setScreenLoader: Function;
  setSubmitResponse: Function;
}

export const ConfigContext = createContext<ConfigContextTypes>({
  progress: 0,
  currentChapter: 0,
  currentScreen: 0,
  chapters: [],
  submitResponse: null,
  showConfirmModal: false,
  errorObject: null,
  errorCode: '',
  screensLength: 0,
  screenLoader: !!reviewID,
  setProgress: () => {},
  setErrorCode: () => {},
  setCurrentScreen: () => {},
  setErrorObject: () => {},
  setCurrentChapter: () => {},
  setShowConfirmModal: () => {},
  setScreenLoader: () => {},
  updateScreenData: () => {},
  setSubmitResponse: () => {},
});
