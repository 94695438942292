/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { darken, rgb } from 'polished';
import { ReactComponent as GoogleLogo } from '../../assets/images/logos/google-logo.svg';

type GoogleButtonProps = {
  text: string;
  onClick?: () => void;
};

export const GoogleButton: React.FC<GoogleButtonProps> = function (props) {
  const { text, onClick } = props;

  const handlerClickButton = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ButtonWrapper role="button" onClick={handlerClickButton}>
      <GoogleLogo width={30} />
      <ButtonText>
        <p>
          {text}
          {' '}
          <b>Google</b>
        </p>
      </ButtonText>
    </ButtonWrapper>
  );
};

GoogleButton.defaultProps = {
  onClick: () => {},
};

const ButtonText = styled.span`
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  color: #fff;
  background-color: rgb(25, 123, 255);
  width: 100%;
  border-radius-top: 10px;
  border-radius-bottom: 10px;
  text-align: left;
  transition: all 0.2s ease;
`;

const ButtonWrapper = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin-top: 30px;
  border: 1px solid ${(props) => props.theme.color.additional};
  border-radius: 10px;
  padding-left: 10px;
  cursor: pointer;
  overflow: hidden;
  background: none;

  &:hover ${ButtonText} {
    background-color: ${(props) => darken(0.1, rgb(25, 123, 255))};
  }
`;
