export const getPercentageIncrease = (numA: number, numB: number): number => {
  if (numA === numB) {
    return 100;
  }

  if (numA !== 0 || numB !== 0) {
    return (numB * 100) / numA;
  }

  return 0;
};
