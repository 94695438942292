import phoneUtil, { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';

class PhoneNumberService {
  private instance: PhoneNumberUtil;

  private country: string;

  constructor() {
    this.country = 'SE';
    this.instance = phoneUtil.PhoneNumberUtil.getInstance();
  }

  parseNumber(phoneNumber: string, country: string = this.country): PhoneNumber {
    return this.instance.parseAndKeepRawInput(phoneNumber, country);
  }

  isValidNumber(phoneNumber: string): boolean {
    const number = this.parseNumber(phoneNumber);
    return this.instance.isValidNumber(number);
  }

  getOriginalFormat(phoneNumber: string): string {
    const number = this.parseNumber(phoneNumber);
    return this.instance.formatInOriginalFormat(number, this.country);
  }
}

export default new PhoneNumberService();
