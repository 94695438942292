import React, { ReactElement, useEffect, useState } from 'react';
import I18NService from '../../service/I18NService';

interface LanguageProviderProps {
  children: ReactElement
}

const LanguageProvider:React.FC<LanguageProviderProps> = function (props) {
  const { children } = props;
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    I18NService.init().then(() => {
      I18NService.onLoadedLanguage().then(() => setIsReady(true));
    });
  }, []);

  return isReady ? children : null;
};

export default LanguageProvider;
