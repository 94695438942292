import * as H from 'history';
import { getReviewIDFromURL } from './getReviewIDFromURL';
import APIReviewsDataService from '../service/API/APIReviewsDataService';
import { ErrorExceptions } from '../types/Models';

const [reviewID, queryParams] = getReviewIDFromURL();

export const handlerStatusRedirect = async (history: H.History, setErrorCode: Function, setScreenLoader: Function) => {
  if (reviewID) {
    APIReviewsDataService.getReviewInfo(reviewID, setErrorCode)
      .then((response) => {
        // @ts-ignore
        const hasReview = response.getReviewRequest()?.getHasReview();

        if (hasReview) {
          setTimeout(() => {
            setErrorCode(ErrorExceptions.ALREADY_COMPLETED);
          }, 500);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setScreenLoader(false);
        }, 500);
      });
  }

  history.push(`/chapter0/screen0${queryParams ? `?${queryParams}` : ''}`);
  return null;
};
