import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as IconArrowForward } from '../../assets/images/icon-arrow-forward.svg';

export interface RoundNextButtonProps {
  onClick: React.MouseEventHandler<HTMLSpanElement>
  disabled?: boolean
}

export const RoundNextButton:React.FC<RoundNextButtonProps> = function (props) {
  const { onClick, disabled } = props;
  return (
    <StyledRoundNextButton onClick={onClick} disabled={disabled}>
      <IconArrowForward />
    </StyledRoundNextButton>
  );
};

RoundNextButton.defaultProps = {
  disabled: false,
};

const StyledRoundNextButton = styled.span<RoundNextButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  cursor: pointer;
  background: ${(props) => props.theme.color.accentColor};
  color: ${(props) => props.theme.color.background};

  ${(props) => props.disabled
    && css`
      pointer-events: none;
      opacity: 0.65;
    `}
`;
