import React, { MouseEventHandler, useContext } from 'react';
import { Screen } from '../../components/Screen/Screen';
import { Form } from '../../components/Form/Form';
import { AnswerList } from '../../components/AnswerList/AnswerList';
import { ErrorScreen } from '../ErrorScreen';
import { ConfigContext } from '../../config';
import { ConfigScreen } from '../../types/Models';

export interface ScreenConfig extends ConfigScreen {
  progress: number;
  isLoading: boolean;
}

export interface QuestionScreenProps {
  onSubmit: Function;
  onDataChange: Function;
  screenIndex: number;
  screensLength: number;
  onBackClick: MouseEventHandler<HTMLButtonElement>;
  screenConfig: ScreenConfig;
}

export const QuestionScreen: React.FC<QuestionScreenProps> = function (props) {
  const {
    onSubmit,
    onDataChange,
    screenConfig,
    screenIndex,
    screensLength,
    onBackClick,
  } = props;

  const {
    id,
    progress,
    type,
    btnConfig,
    formTitle,
    formDescription,
    canSelectMultiple,
    data,
    submittable,
    submitAction,
    isLoading,
    noProgress,
  } = screenConfig;
  const {
    showConfirmModal, setShowConfirmModal, errorObject, chapters, currentChapter, currentScreen,
  } = useContext(ConfigContext);
  const isConfirmScreen = chapters[currentChapter].screens[currentScreen]?.confirmScreen;

  const handlerSubmitScreen = () => {
    if (isConfirmScreen) {
      setShowConfirmModal(true);

      if (showConfirmModal) {
        onSubmit(submitAction);
        setShowConfirmModal(false);
      }
    } else {
      onSubmit(submitAction);
    }
  };

  return errorObject ? (
    <ErrorScreen errorObject={errorObject} />
  ) : (
    <Screen
      id={id}
      progress={progress}
      type={type}
      btnConfig={{
        ...btnConfig,
        onClick: submittable ? handlerSubmitScreen : () => {
        },
        disabled: !submittable,
        isLoading,
      }}
      noProgress={noProgress}
      screenIndex={screenIndex}
      screensLength={screensLength}
      onBackClick={onBackClick}
    >
      <Form type={type} title={formTitle} description={formDescription} id={id}>
        <AnswerList
          answers={data?.answers || []}
          canSelectMultiple={canSelectMultiple}
          onChange={onDataChange}
        />
      </Form>
    </Screen>
  );
};
