import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ReactComponent as LoaderIcon } from '../../assets/images/loader-icon.svg';

interface LoaderProps {
  enabled: boolean;
  children: ReactElement | string;
}

export const Loader:React.FC<LoaderProps> = function ({ enabled, children }) {
  return <LoaderWrapper>{enabled ? <LoaderIcon width={100} /> : children}</LoaderWrapper>;
};

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
