import { reviewPromptTypes } from 'patient-api-lib';
import { OverviewStepId } from './types/Models';

interface EnvConfig {
  NODE_ENV: 'development' | 'production' | 'test';
  PUBLIC_PATIENT_API_KEY: string;
  PATIENT_API: string;
  GROWTHBOOK_API_KEY: string;
  RUDDERSTACK_API_KEY: string;
  RUDDERSTACK_DATAPLANE_URL: string;
  DEV_MODE: boolean
}

interface SettingsTypes {
  DEFAULT_TIMEZONE: string;
  SWEDISH_PHONE_LENGTH: number;
}

const envConfig: EnvConfig = {
  NODE_ENV: process.env.NODE_ENV || '',
  PUBLIC_PATIENT_API_KEY: process.env.REACT_APP_PUBLIC_PATIENT_API_KEY || '{{PUBLIC_PATIENT_API_KEY}}',
  PATIENT_API: process.env.REACT_APP_PATIENT_API || '{{PATIENT_API}}',
  GROWTHBOOK_API_KEY: process.env.REACT_APP_GROWTHBOOK_API_KEY || '{{GROWTHBOOK_API_KEY}}',
  RUDDERSTACK_API_KEY: process.env.REACT_APP_RUDDERSTACK_API_KEY || '{{RUDDERSTACK_API_KEY}}',
  RUDDERSTACK_DATAPLANE_URL: process.env.REACT_APP_RUDDERSTACK_DATAPLANE_URL || '{{RUDDERSTACK_DATAPLANE_URL}}',
  DEV_MODE: process.env.NODE_ENV === 'development',
};

export const SMS_FLOW_DISABLE_STEPS: OverviewStepId[] = ['SELECTOR_CLINIC', 'USER_DETAILS'];
export const WIDGET_FLOW_DISABLE_STEPS: OverviewStepId[] = ['SELECTOR_CLINIC'];

export const SETTINGS: SettingsTypes = {
  DEFAULT_TIMEZONE: 'Europe/Stockholm',
  SWEDISH_PHONE_LENGTH: 19,
};

export const DEFAULTS = {
  defaultLocation: {
    latitude: 59.32893,
    longitude: 18.06491,
  },
};

export const REVIEW_PROMPT_NAME = {
  [reviewPromptTypes.unspecified]: 'unspecified',
  [reviewPromptTypes.google]: 'Google',
  [reviewPromptTypes.trustpilot]: 'Trustpilot',
};

export default envConfig;
