import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as IconDone } from '../../assets/images/icon-done.svg';
import { theme } from '../../theme';
import { AnswerTypes } from '../Answer/Answer';

interface StyledCheckBoxProps extends AnswerTypes {
  checked: boolean;
}

export interface CheckBoxProps extends StyledCheckBoxProps {
  onChange: Function;
}

export const CheckBox: React.FC<CheckBoxProps> = function (props) {
  const { checked, onChange, styleType } = props;

  return (
    <StyledCheckBox checked={checked} styleType={styleType}>
      {checked && <IconDone />}
      <HiddenCheckBox checked={checked} onChange={(e) => onChange(e.target.checked)} />
    </StyledCheckBox>
  );
};

const StyledCheckBox = styled.label<StyledCheckBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.color.disabledColor};
  color: ${(props) => props.theme.color.background};
  cursor: pointer;

  ${(props) => css`
    .fill-color {
      fill: ${(props) => props.theme.color.textColor};
    }
  `};

  ${(props) => props.checked
    && css`
      background: ${(props) => props.theme.color.accentColor};
      border: 1px solid ${(props) => props.theme.color.accentColor};
    `}

  ${(props) => props.styleType === 'square'
    && css`
      border-radius: 4px;
      width: 30px;
      height: 30px;
    `};
`;

const HiddenCheckBox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;
