import React, { JSXElementConstructor, ReactElement, useEffect } from 'react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { detect } from 'detect-browser';
import RudderAnalyticsService from '../../service/RudderAnalyticsService';
import envConfig from '../../constants';
import I18NService from '../../service/I18NService';

interface GBAttributes {
  deviceId: string;
  languge: string;
  browser?: string;
}

interface GrowthBookProviderWrapperProps {
  children: ReactElement<any, string | JSXElementConstructor<any>> | string;
}

export const growthbook = new GrowthBook({
  enableDevMode: process.env.NODE_ENV === 'development',
  trackingCallback: (experiment, result) => {
    const data = {
      experimentId: experiment.key,
      variationId: result.variationId,
    };
    RudderAnalyticsService.trackGBExperiments(data);
  },
});

const { GROWTHBOOK_API_KEY } = envConfig;
const FEATURES_ENDPOINT = `https://cdn.growthbook.io/api/features/${GROWTHBOOK_API_KEY}`;

export const GrowthBookProviderWrapper:React.FC<GrowthBookProviderWrapperProps> = function (props) {
  const { children } = props;
  const browser = detect();
  const locale = I18NService.currentLocale;

  useEffect(() => {
    fetch(FEATURES_ENDPOINT)
      .then((res) => res.json())
      .then((json) => growthbook.setFeatures(json.features))
      .catch((e) => new Error('Failed to fetch features', e));

    const attributes: GBAttributes = {
      deviceId: RudderAnalyticsService.getUserID(),
      languge: locale,
    };

    if (browser) {
      attributes.browser = `${browser.name}, ${browser.version}, ${browser.os}`;
    }

    growthbook.setAttributes(attributes);
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};
