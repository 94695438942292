import React, { MouseEventHandler, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconLeft } from '../../assets/images/icon-left.svg';
import { theme } from '../../theme';
import { CloseButton } from '../CloseButton/CloseButton';
import { StatusBar } from '../StatusBar/StatusBar';
import { closeAppByMessage } from '../../utils/windowMessage';
import { getWidgetConfig } from '../../helpers/getWidgetConfig';

export interface ScreenHeaderProps {
  progress: number;
  screenIndex: number;
  screensLength: number;
  onBackClick?: MouseEventHandler<HTMLButtonElement>;
}

export const ScreenHeader: React.FC<ScreenHeaderProps> = function (props) {
  const {
    progress, screensLength, screenIndex, onBackClick,
  } = props;
  const { t } = useTranslation();
  const [_, hasWidgetConfig] = getWidgetConfig();

  const showBackButton = useMemo(() => {
    if (screenIndex === 0) {
      return false;
    }

    return screenIndex !== screensLength;
  }, [screenIndex, screensLength]);

  const stepLenght = useMemo(() => {
    const numberOfScreen = screenIndex + 1;

    return t('generals.stepText', { currentStep: numberOfScreen, stepLength: screensLength });
  }, [screenIndex, screensLength]);

  const handlerClickCloseButton = () => {
    closeAppByMessage();
  };

  return (
    <StyledScreenHeader>
      <Row style={{ padding: '8px 15px 10px 15px' }}>
        <StatusBar progress={progress} />
        {hasWidgetConfig && <CloseButton onClick={handlerClickCloseButton} />}
      </Row>
      <Row>
        {showBackButton && (
          <Button onClick={onBackClick}>
            <IconLeft />
          </Button>
        )}

        <SepText>{stepLenght}</SepText>
      </Row>
    </StyledScreenHeader>
  );
};

ScreenHeader.defaultProps = {
  onBackClick: () => {},
};

const StyledScreenHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${(props) => {
    if (props.theme.isMobile) {
      return props.theme.color.additional;
    }
    return props.theme.color.background;
  }};

  ${() => theme.color.accentColor
    && css`
      & svg path {
        stroke: ${() => theme.color.accentColor}
      }
    `}
`;

const SepText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  position: relative;
`;

const Button = styled.button`
  position: absolute;
  top: -2px;
  left: 12px;
  background: none;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
