import { ConfigScreen } from '../types/Models';
import { testValue } from './testValue';

export const checkScreenSubmittable = (screen: ConfigScreen) => {
  if (!screen.data) return false;

  const validatedAnswers = screen.data.answers.filter((answer) => {
    if (answer.type === 'check_and_text') {
      return answer.inputValue !== '' && !!answer.selected;
    }

    if (answer.type === 'check_more') {
      return false;
    }

    if (answer.type === 'check_and_date') {
      return answer.inputValue !== '' && !!answer.selected;
    }

    return answer.selected;
  });

  const validateCheckAndText = screen.data.answers.filter(
    (answer) => answer.type === 'check_and_text' && answer.selected && answer.inputValue === '',
  );

  const validateTextInput = screen.data.answers.filter(
    (answer) => answer.type === 'text-input'
    && answer.inputOptions?.required
    && answer.inputValue === '',
  );

  const validateTestValue = screen.data.answers.filter(
    (answer) => answer.type === 'text-input'
    && testValue(answer.inputOptions?.testValue, answer.inputValue),
  );

  const validateRadioInput = screen.data.answers.filter(
    (answer) => answer.type === 'radio' && answer.inputValue === '',
  );

  const validateRatingInput = screen.data.answers.filter(
    (answer) => answer.type === 'rating' && !answer.selected,
  );

  const isValidated = validatedAnswers.length !== 0
  && validateCheckAndText.length === 0
  && validateTextInput.length === 0
  && validateRadioInput.length === 0
  && validateRatingInput.length === 0
  && validateTestValue.length === 0;

  return isValidated;
};
