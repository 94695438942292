export const getUrlScreenNumber = (pathname: string): number => {
  try {
    const result = pathname.split('/')[2].replace('screen', '');
    if (Number.isNaN(Number(result))) return 0;

    return Number(result);
  } catch (error) {
    return 0;
  }
};

export const getUrlChapterNumber = (pathname: string): number => {
  try {
    const result = pathname.split('/')[1].replace('chapter', '');
    if (Number.isNaN(Number(result))) return 0;

    return Number(result);
  } catch (error) {
    return 0;
  }
};
