import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { ReactComponent as TrustpilotStarLogo } from '../../assets/images/logos/trustpilot-star-logo.svg';

type TruspilotButtonProps = {
  text: string;
  onClick?: () => void;
}

export const TruspilotButton:React.FC<TruspilotButtonProps> = function (props) {
  const { text, onClick } = props;

  const handlerClickButton = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ButtonWrapper onClick={handlerClickButton}>
      <TrustpilotStarLogo height={48} width={30} />
      <ButtonText>
        {' '}
        <p>
          {text}
          {' '}
          <b>Trustpilot</b>
        </p>
      </ButtonText>
    </ButtonWrapper>
  );
};

TruspilotButton.defaultProps = {
  onClick: () => {},
};

const ButtonText = styled.span`
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  color: #fff;
  background-color: #38b67a;
  width: 100%;
  border-radius-top: 10px;
  border-radius-bottom: 10px;
  text-align: left;
  transition: all 0.2s ease;
`;

const ButtonWrapper = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin-top: 30px;
  border: 1px solid ${(props) => props.theme.color.additional};
  border-radius: 10px;
  padding-left: 10px;
  cursor: pointer;
  overflow: hidden;
  background: none;

  &:hover ${ButtonText} {
    background-color: ${(props) => darken(0.1, '#38b67a')};
  }
`;
