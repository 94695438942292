export const getReviewIDFromPathName = () => {
  try {
    const pathName = window.location.pathname;
    const splitPathName = pathName.split('/')[1];
    return splitPathName;
  } catch (error) {
    return null;
  }
};

export const getReviewIDFromURL = (): [string | null, string | null] => {
  const urlParams = new URLSearchParams(window.location.search);
  const pathNameID = getReviewIDFromPathName();
  const isNotRoute = pathNameID !== 'chapter0';

  const getReviewID = () => {
    if (urlParams.get('id')) {
      return urlParams.get('id');
    } if (!!urlParams.get('id') || isNotRoute) {
      return pathNameID;
    }

    return null;
  };
  const reviewID = getReviewID();
  const payload = reviewID && { id: reviewID };
  const queryParams = (payload && new URLSearchParams(payload).toString()) || null;

  return [reviewID, queryParams];
};
