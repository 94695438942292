import React from 'react';
import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';
import { InputOptions } from '../../types/Models';

export interface TextInputProps {
  title: string | undefined;
  value: string | undefined;
  onChange: Function;
  onBlur: React.FocusEventHandler<HTMLInputElement> | undefined;
  onFocus: React.FocusEventHandler<HTMLInputElement> | undefined;
  placeholder: string | undefined;
  showError?: boolean;
  inputOptions: InputOptions;
  testId: string;
}

export const TextInput: React.FC<TextInputProps> = function (props) {
  const {
    title, value, onChange, onBlur, onFocus, placeholder, inputOptions, showError, testId,
  } = props;
  const {
    type, mask, errorMessage, required, maskPlaceholder, alwaysShowMask, inputMode, testValue,
  } = inputOptions;

  const handlerChangeInput = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <Container>
      {title && <Title>{title}</Title>}
      <InputMask
        mask={mask || ''}
        maskPlaceholder={maskPlaceholder}
        alwaysShowMask={alwaysShowMask}
        value={value}
        onChange={handlerChangeInput}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <StyledTextArea
          type={type}
          inputMode={inputMode}
          data-testid={testId}
          placeholder={placeholder}
          required={required}
        />
      </InputMask>
      {errorMessage && showError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};

TextInput.defaultProps = {
  showError: false,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${() => css`
    input {
      width: 100%;
      border: 1px solid ${(props) => props.theme.color.disabledColor};
      color: ${(props) => props.theme.color.textColor};
      font-size: ${(props) => props.theme.typography.size.breadM};
      border-radius: 4px;
      padding: 12px;
      box-sizing: border-box;
      resize: none;
      outline: none;

      ::placeholder,
      ::-webkit-input-placeholder {
        color: ${(props) => props.theme.color.disabledColor};
      }
      :-ms-input-placeholder {
        color: ${(props) => props.theme.color.disabledColor};
      }
    }
  `};
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.color.error};
  font-size: ${(props) => props.theme.typography.size.subHeadingS};
  font-weight: ${(props) => props.theme.typography.weight.medium};
  margin: 7px 0 0px 0;
  line-height: 1;
`;

const StyledTextArea = styled.input`
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.disabledColor};
  color: ${(props) => props.theme.color.textColor};
  font-size: ${(props) => props.theme.typography.size.breadM};
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
  resize: none;
  outline: none;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.color.disabledColor};
  }
  :-ms-input-placeholder {
    color: ${(props) => props.theme.color.disabledColor};
  }
`;

const Title = styled.p`
  font-size: ${(props) => props.theme.typography.size.breadM};
  color: ${(props) => props.theme.color.textColor};
  margin: 0 0 8px;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.typography.weight.medium};
`;
