import { getReviewIDFromURL } from './getReviewIDFromURL';
import { getWidgetConfig } from './getWidgetConfig';
import RudderAnalyticsService from '../service/RudderAnalyticsService';

const [_, hasWidgetConfig] = getWidgetConfig();
const [reviewID] = getReviewIDFromURL();

export const sendingEventByFlow = () => {
  if (reviewID) {
    RudderAnalyticsService.trackOpenBySMS();
  } else if (hasWidgetConfig) {
    RudderAnalyticsService.trackOpenAsWidget();
  } else {
    RudderAnalyticsService.trackOpenDirectly();
  }
};
