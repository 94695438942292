import React from 'react';
import styled from 'styled-components/macro';

export interface InactiveStepProps {
  title: string
}

export const InactiveStep:React.FC<InactiveStepProps> = function (props) {
  const { title } = props;
  return (
    <StyledInactiveStep>
      <Title>{title}</Title>
    </StyledInactiveStep>
  );
};

const StyledInactiveStep = styled.div`
  padding-left: 16px;
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    height: 60%;
    width: 3px;
    position: absolute;
    left: -2px;
    background: ${(props) => props.theme.color.disabledColor};
    border-radius: 4px;
  }
`;

const Title = styled.h3`
  color: ${(props) => props.theme.color.disabledColor};
  font-weight: ${(props) => props.theme.typography.weight.bold};
  font-size: ${(props) => props.theme.typography.size.heading3};
`;
