import * as rudderanalytics from 'rudder-sdk-js';
import envConfig from '../constants';
import { IRudderAnalyticsService } from './interfaces/IRudderAnalyticsService';

class RudderAnalyticsService implements IRudderAnalyticsService {
  private readonly RUDDERSTACK_API_KEY: string;

  private readonly RUDDERSTACK_DATAPLANE_URL: string;

  private _rudderanalytics: any;

  private commonFields: {};

  constructor() {
    this.RUDDERSTACK_API_KEY = envConfig.RUDDERSTACK_API_KEY;
    this.RUDDERSTACK_DATAPLANE_URL = envConfig.RUDDERSTACK_DATAPLANE_URL;
    this._rudderanalytics = null;
    this.commonFields = {};
  }

  initialize() {
    rudderanalytics.ready(() => {});
    rudderanalytics.load(this.RUDDERSTACK_API_KEY, this.RUDDERSTACK_DATAPLANE_URL, {
      logLevel: process.env.NODE_ENV === 'development' ? 'DEBUG' : 'ERROR',
      integrations: { All: true },
    });

    this._rudderanalytics = rudderanalytics;
  }

  getUserID() {
    if (this._rudderanalytics === null) return;

    return this._rudderanalytics.getAnonymousId();
  }

  trackGBExperiments(data: rudderanalytics.apiObject | undefined) {
    if (this._rudderanalytics === null) return;

    this._rudderanalytics.track('track-gb-experiment', data);
  }

  trackSentReview() {
    if (this._rudderanalytics === null) return;

    this._rudderanalytics.track('sent-review');
  }

  trackOpenAsWidget() {
    if (this._rudderanalytics === null) return;

    this._rudderanalytics.track('open-as-widget');
  }

  trackOpenBySMS() {
    if (this._rudderanalytics === null) return;

    this._rudderanalytics.track('open-by-sms');
  }

  trackOpenDirectly() {
    if (this._rudderanalytics === null) return;

    this._rudderanalytics.track('open-directly');
  }

  trackTransitToGoogleReview(googleReviewLink: string) {
    if (this._rudderanalytics === null) return;

    this._rudderanalytics.track('transit-to-google-review', {
      link: googleReviewLink,
    });
  }

  trackTransitToTrustpilotReview(trustpilotReviewLink: string) {
    if (this._rudderanalytics === null) return;

    this._rudderanalytics.track('transit-to-trustpilot-review', {
      link: trustpilotReviewLink,
    });
  }
}

export default new RudderAnalyticsService();
