import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Step } from '../Step/Step';
import { InactiveStepList } from '../InactiveStepList/InactiveStepList';
import { DoneStepList } from '../DoneStepList/DoneStepList';
import { OverviewStepId } from '../../types/Models';
import { ConfigContext } from '../../config';

export interface StepProps {
  id: OverviewStepId
  title: string;
  description: string;
  state: 'active' | 'done' | 'inactive';
}

export interface StepListProps {
  steps: StepProps[];
  onStepClick: Function
}

export const StepList: React.FC<StepListProps> = function (props) {
  const { steps, onStepClick } = props;
  const { chapters, currentScreen } = useContext(ConfigContext);
  const isLastScreen = chapters[0].screens[currentScreen].id === 'SUCCESS';

  const activeStep = steps.filter((step) => step.state === 'active')[0];
  const doneSteps = steps.map((step) => [{ ...step, state: 'done' }])[0];
  const inactiveSteps = steps.filter((step) => step.state === 'inactive');

  return (
    <StyledStepList>
      {isLastScreen && (
        <>
          <DoneStepList steps={doneSteps} />
          <Spacer />
        </>
      )}
      {!isLastScreen && activeStep && <Step {...activeStep} onClick={() => onStepClick(activeStep.id)} />}
      <InactiveStepList steps={inactiveSteps} />
    </StyledStepList>
  );
};

const StyledStepList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`;

const Spacer = styled.span`
  height: 10px;
`;
