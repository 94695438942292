import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { InactiveStep } from '../InactiveStep/InactiveStep';

export interface InactiveStepListProps {
  steps: { title: string }[];
}

export const InactiveStepList: React.FC<InactiveStepListProps> = function (props) {
  const { steps } = props;

  if (steps.length === 0) return null;

  return (
    <StyledInactiveStepList>
      <StepsContainer>
        {steps.map((step, index) => (
          <Fragment key={index}>
            <InactiveStep title={step.title} />
            {index < steps.length - 1 && <Spacer />}
          </Fragment>
        ))}
      </StepsContainer>
    </StyledInactiveStepList>
  );
};

const StyledInactiveStepList = styled.div`
  padding-top: 12px;
  border-left: 1px solid ${(props) => props.theme.color.disabledColor};
  margin: 0 24px;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Spacer = styled.span`
  height: 24px;
`;
