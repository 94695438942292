import React, {
  ReactElement, useRef,
} from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import { ScreenType, TypeID } from '../../types/Models';

export interface FormProps {
  title?: string | null;
  description?: string | string[] | null;
  children?: ReactElement | string;
  type: ScreenType
  id?: TypeID;
}

interface StyledFormProps {
  type: ScreenType
}

export const Form: React.FC<FormProps> = function (props) {
  const {
    title, description, children, type, id,
  } = props;
  const formRef = useRef(null);

  const handlerSubmitForm = (e: any) => {
    e.preventDefault();
  };

  return (
    <StyledForm ref={formRef} onSubmit={handlerSubmitForm} type={type} id={id}>
      <Title type={type}>{title}</Title>
      {description && typeof description === 'object' ? (
        description.map((item, index) => <Description key={index}>{item}</Description>)
      ) : (
        <Description>{description}</Description>
      )}
      {children && children}
    </StyledForm>
  );
};

Form.defaultProps = {
  title: undefined,
  description: undefined,
  children: undefined,
  id: undefined,
};

const StyledForm = styled.form<StyledFormProps>`
  border-radius: 20px;
  background: ${(props) => {
    if (props.type === 'authorization') {
      return 'none';
    }
    return props.theme.color.background;
  }};
  padding: ${(props) => {
    if (props.theme.isMobile) {
      return '32px 24px';
    }
    if (props.type === 'authorization') {
      return '30px 24px 32px 24px';
    }

    if (props.type === 'selector') {
      return '0 0 32px 0';
    }
    return '0px 24px 15px 24px';
  }};
  box-sizing: border-box;
  box-shadow: none;
  margin-bottom: ${(props) => {
    if (props.theme.breakpoints.tablet) {
      return '';
    }
    return '30px';
  }} {

  };

  @media ${(props) => props.theme.breakpoints.tablet} {
    box-shadow: ${(props) => {
    if (props.type === 'authorization') {
      return 'none';
    }
    return `${rgba('#000000', 0.08)} 0 2px 2px 0`;
  }};
  }
`;

const Title = styled.h1<StyledFormProps>`
  font-size: ${(props) => props.theme.typography.size.heading1};
  font-weight: ${(props) => props.theme.typography.weight.bold};
  color: ${(props) => props.theme.color.textColor};
  line-height: 30px;
  position: relative;
  padding: 0 0 10px;
  margin: ${(props) => {
    if (props.type === 'authorization') {
      return '0 0 8px';
    }
    return '0 0 8px';
  }};

  &::after {
    content: '';
    width: 32px;
    height: 2px;
    background: ${(props) => props.theme.color.accentColor};
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 4px;
  }
`;

const Description = styled.p`
  font-size: ${(props) => props.theme.typography.size.breadM};
  font-weight: ${(props) => props.theme.typography.weight.regular};
  line-height: 24px;
  color: ${(props) => props.theme.color.textColor};
  margin: 0 0 24px;
`;
