import { IStorageService } from './interfaces/IStorageService';

class LocalStorageService implements IStorageService {
  private localStorage = window.localStorage;

  putData(key: string, value: string) {
    this.localStorage.setItem(key, value);
  }

  getData(key: string): string | null {
    return this.localStorage.getItem(key);
  }

  getParsedData<R>(key: string): R {
    return JSON.parse(this.localStorage.getItem(key) as string);
  }

  hasData(key: string): boolean {
    return this.localStorage.getItem(key) !== null;
  }

  clearData(key: string): void {
    this.localStorage.removeItem(key);
  }
}

export default new LocalStorageService();
