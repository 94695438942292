import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Rating } from 'react-simple-star-rating';
import { CheckBox } from '../CheckBox/CheckBox';
import { TextArea } from '../TextArea/TextArea';
import { AnswerData, ChildAnswer, DirectionAnswers } from '../../types/Models';
import { TextInput } from '../TextInput/TextInput';
import { RadioButton } from '../RadioButton/RadioButton';
import { ReactComponent as IconStarEmpty } from '../../assets/images/icon-star-empty.svg';
import { ReactComponent as IconStarfull } from '../../assets/images/icon-star-full.svg';
import { testValue } from '../../utils/testValue';
import PhoneNumberService from '../../service/PhoneNumberService';
import { SETTINGS } from '../../constants';
import { REGEX_PATTERNS } from '../../utils/RegexUtils';
import { ConfigContext } from '../../config';

export interface AnswerProps {
  answer: AnswerData;
  onChange: Function;
}

interface UpdateAnswerTypes {
  id?: number;
  selected?: boolean;
  inputValue?: string | number;
  answers?: AnswerData[] | ChildAnswer[];
}

export type AnswerTypes = {
  readonly styleType?: 'circle' | 'square' | 'rating' | 'text-input';
};

export const Answer: React.FC<AnswerProps> = function (props) {
  const { answer, onChange } = props;
  const { chapters, currentChapter, currentScreen } = useContext(ConfigContext);
  const screenData = chapters[currentChapter].screens[currentScreen];
  const [showError, setShowError] = useState<boolean>(false);

  const updateAnswer = (data: UpdateAnswerTypes) => {
    const newData = {
      ...answer,
      ...data,
    };
    onChange(newData);
  };

  if (answer.type === 'check') {
    return (
      <StyledAnswer>
        <Text>{answer.text}</Text>
        <CheckBox styleType="circle" checked={answer.selected} onChange={(checked: boolean) => updateAnswer({ selected: checked })} />
      </StyledAnswer>
    );
  }

  if (answer.type === 'radio') {
    const changeRadioAnswers = (index: number): ChildAnswer[] | undefined => {
      const temporaryAnswers = answer.answers ? [...answer.answers] : [];

      temporaryAnswers.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.selected = false;
      });

      temporaryAnswers[index].selected = true;

      return temporaryAnswers;
    };

    return (
      <>
        <RadioTitle>{answer.inputTitle}</RadioTitle>
        <AnswersRow directionAnswers={answer.directionAnswers || 'column'}>
          {answer.answers?.map((ans, index) => (
            <StyledAnswer styleType="square" key={index}>
              <Text styleType="square">{ans.label}</Text>
              <RadioButton
                styleType="square"
                checked={ans.selected}
                onChange={(checked: boolean) => updateAnswer({
                  selected: checked,
                  inputValue: ans.value,
                  answers: changeRadioAnswers(index),
                })}
                testId={ans.testId}
              />
            </StyledAnswer>
          ))}
        </AnswersRow>
      </>
    );
  }

  if (answer.type === 'check_more') {
    return (
      <StyledAnswer styleType="square">
        <Text styleType="square">{answer.text}</Text>
        <CheckBox checked={answer.selected} onChange={(checked: boolean) => updateAnswer({ selected: checked })} styleType="square" />
      </StyledAnswer>
    );
  }

  if (answer.type === 'text') {
    const handlerChangeTextInput = (value: string) => {
      updateAnswer({
        inputValue: value,
        selected: value !== '',
        id: answer.id,
      });

      if (screenData?.confirmScreen) {
        screenData.confirmScreen.content = value;
      }
    };

    return (
      <StyledAnswer>
        <TextArea
          onBlur={() => {}}
          onFocus={() => {}}
          title={answer.inputTitle}
          value={answer.inputValue}
          placeholder={answer.placeholder}
          onChange={handlerChangeTextInput}
          testId={answer.testId}
        />
      </StyledAnswer>
    );
  }

  if (answer.type === 'text-input' && answer.inputOptions) {
    const validateInput = () => {
      let validate = false;
      if (!answer.inputValue) return;
      if (answer.inputOptions?.inputMode === 'tel') {
        if (answer.inputValue.length === SETTINGS.SWEDISH_PHONE_LENGTH && testValue(REGEX_PATTERNS.phone, answer.inputValue)) {
          validate = !PhoneNumberService.isValidNumber(answer.inputValue);

          updateAnswer({ selected: !validate });
        } else {
          updateAnswer({ selected: false });
          setShowError(validate);
        }
      } else {
        validate = testValue(answer.inputOptions?.testValue, answer.inputValue);
        updateAnswer({ selected: !validate });
      }

      setShowError(validate);
    };

    const handlerBlurTextInput = () => {
      validateInput();
    };

    const handlerChangeInput = (value: string) => {
      updateAnswer({ inputValue: value });
    };

    return (
      <StyledAnswer styleType="text-input">
        <TextInput
          inputOptions={answer.inputOptions}
          onBlur={handlerBlurTextInput}
          onFocus={() => {}}
          title={answer.inputTitle}
          value={answer.inputValue}
          placeholder={answer.placeholder}
          showError={showError}
          onChange={handlerChangeInput}
          testId={answer.testId}
        />
      </StyledAnswer>
    );
  }

  if (answer.type === 'rating') {
    const handleChangeRating = (rate: number) => {
      updateAnswer({ inputValue: rate, selected: true, id: answer.id });
    };

    return (
      <StyledAnswer styleType="rating">
        <RadioTitle>{answer.inputTitle}</RadioTitle>
        <Rating
          transition
          disableFillHover
          fillIcon={<IconStarfull width={50} height={30} />}
          emptyIcon={<IconStarEmpty width={50} height={30} />}
          SVGstyle={{ padding: '15px' }}
          style={{ marginBottom: '10px', marginTop: '10px', marginLeft: '-13px' }}
          onClick={handleChangeRating}
          initialValue={Number(answer.inputValue)}
        />
      </StyledAnswer>
    );
  }

  if (answer.type === 'check_and_text') {
    return (
      <>
        <StyledAnswer>
          <Text>{answer.text}</Text>
          <CheckBox
            styleType="circle"
            checked={answer.selected}
            onChange={(checked: boolean) => {
              updateAnswer({ selected: checked });
            }}
          />
        </StyledAnswer>

        {answer.selected && (
          <>
            <Spacer />
            <StyledAnswer>
              <TextArea
                title={answer.inputTitle}
                value={answer.inputValue}
                placeholder={answer.placeholder}
                onChange={(value: string) => updateAnswer({ inputValue: value })}
                onBlur={() => {}}
                onFocus={() => {}}
                testId={answer.testId}
              />
            </StyledAnswer>
          </>
        )}
      </>
    );
  }

  return null;
};

const StyledAnswer = styled.label<AnswerTypes>`
  display: flex;
  align-items: center;
  padding: 17px 0;
  width: 100%;

  ${(props) => props.styleType === 'square'
    && css`
      flex-direction: row-reverse;
    `};

  ${(props) => props.styleType === 'text-input'
    && css`
      padding-top: 0;
    `};

  ${(props) => props.styleType === 'rating'
    && css`
      align-items: flex-start;
      flex-direction: column;
      padding-bottom: 0;

      .star-fill {
        fill: ${(props) => props.theme.color.accentColor};
      }

      .star-empty {
        stroke: ${(props) => props.theme.color.disabledColor};
      }
    `};
`;

const Text = styled.p<AnswerTypes>`
  color: ${(props) => props.theme.color.textColor};
  font-size: ${(props) => props.theme.typography.size.breadM};
  flex: 1;
  line-height: 20px;
  ${(props) => props.styleType === 'square'
    && css`
      margin-left: 12px;
    `};
`;

const AnswersRow = styled.div<{ directionAnswers: DirectionAnswers }>`
  display: flex;
  flex-direction: ${(props) => props.directionAnswers};
  ${() => css`
    & > label:not(:last-child) {
      margin-right: 15px;
    }
  `};
`;

const RadioTitle = styled.p`
  font-size: ${(props) => props.theme.typography.size.breadM};
  color: ${(props) => props.theme.color.textColor};
  margin: 0 0 8px;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.typography.weight.medium};
`;

const Spacer = styled.span`
  display: block;
`;
