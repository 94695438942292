import i18n, { TFunctionKeys, TOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  Locale,
} from 'date-fns';
import { enUS, sv } from 'date-fns/locale';
import {
  II18NService, DEFAULT_LANG, LANGUAGE,
} from './interfaces/II18NService';
import LocalStorageService from './LocalStorageService';
import envConfig from '../constants';

export const locales: { [key: string]: Locale } = {
  en: enUS, sv,
};

class I18NService implements II18NService {
  public storageLngKey = 'i18nextLng';

  public currentLocale = DEFAULT_LANG;

  private backendConfigs = {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  };

  private i18n = i18n;

  async init() {
    await i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: DEFAULT_LANG,
        lng: this.initLocale(),
        ns: ['translation', 'errors'],
        defaultNS: 'translation',
        supportedLngs: ['sv', 'en'],
        backend: this.backendConfigs,
        debug: envConfig.NODE_ENV === 'development',
      });

    return Promise.resolve(this.i18n);
  }

  private initLocale() {
    const localeFromStorage = LocalStorageService.getData(this.storageLngKey);
    const localeFromNavigator = window.navigator.language.toLowerCase().split('-')[0];
    const locale = localeFromStorage || localeFromNavigator;

    if (!Object.keys(locales).includes(locale)) {
      this.currentLocale = DEFAULT_LANG;
    } else {
      this.currentLocale = locale as LANGUAGE;
    }

    LocalStorageService.putData(this.storageLngKey, this.currentLocale);
    return this.currentLocale;
  }

  async changeLanguage(lang: string): Promise<void> {
    await this.i18n.changeLanguage(lang);
  }

  async onLoadedLanguage(): Promise<void> {
    this.i18n.on('loaded', () => Promise.resolve());
  }

  getCurrentLanguage(): Promise<string> {
    return Promise.resolve(this.i18n.language);
  }

  onLanguageChanged(callback: (language: string) => void) {
    return this.i18n.on('languageChanged', callback);
  }

  getTranslation(keys: TFunctionKeys | TFunctionKeys[], options?: TOptions): string {
    return this.i18n.t(keys, options);
  }
}

export default new I18NService();
