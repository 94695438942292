import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { initApiClient } from 'patient-api-lib';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalStyle } from './GlobalStyle';
import { RudderstackProvider } from './components/RudderstackProvider';
import { GrowthBookProviderWrapper } from './components/GrowthBookProviderWrapper';
import envConfig from './constants';
import LanguageProvider from './components/LanguageProvider/LanguageProvider';
import AppThemeProvider from './components/AppThemeProvider/AppThemeProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const { PUBLIC_PATIENT_API_KEY, PATIENT_API, DEV_MODE } = envConfig;

initApiClient(PATIENT_API, PUBLIC_PATIENT_API_KEY, DEV_MODE);

root.render(
  <AppThemeProvider>
    <GlobalStyle />
    <LanguageProvider>
      <RudderstackProvider>
        <GrowthBookProviderWrapper>
          <Router>
            <App />
          </Router>
        </GrowthBookProviderWrapper>
      </RudderstackProvider>
    </LanguageProvider>
  </AppThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
