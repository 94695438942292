/* eslint-disable max-len */
import React, { DetailedHTMLProps, HTMLAttributes, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { mix } from 'polished';
import { useTranslation } from 'react-i18next';
import { useFeature } from '@growthbook/growthbook-react';
import { reviewPromptTypes } from 'patient-api-lib';
import { ConfigContext } from '../../config';
import { ScreenHeader } from '../../components/ScreenHeader/ScreenHeader';
import { Button } from '../../components/Button/Button';
import { ButtonConfig, GrowthBookFeatures } from '../../types/Models';
import { ReactComponent as IconToothSmileSidebar } from '../../assets/images/icon-tooth-smile-sidebar.svg';
import { theme } from '../../theme';
import { closeAppByMessage } from '../../utils/windowMessage';
import { getWidgetConfig } from '../../helpers/getWidgetConfig';
import { ErrorScreen } from '../ErrorScreen';
import { GoogleButton } from '../../components/GoogleButton/GoogleButton';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import RudderAnalyticsService from '../../service/RudderAnalyticsService';
import { TruspilotButton } from '../../components/TruspilotButton/TruspilotButton';

export interface ConfirmationScreenProps {
  formTitle?: string;
  formDescription?: string | string[] | null;
  formPrescription?: string | null;
  noProgress?: boolean;
  progress: number;
  screenIndex: number;
  screensLength: number;
  btnConfig?: ButtonConfig;
}

export const ConfirmationScreen: React.FC<ConfirmationScreenProps> = function (props) {
  const {
    formTitle, formDescription, formPrescription, noProgress, progress, screenIndex, screensLength, btnConfig,
  } = props;
  const [_, hasWidgetConfig] = getWidgetConfig();
  const { t } = useTranslation();
  const history = useHistory();
  const { errorObject, submitResponse } = useContext(ConfigContext);
  const [__, copy] = useCopyToClipboard();
  const navigateToPreviousPage = () => history.goBack();
  const sendedReview = submitResponse && submitResponse.getReview();
  const messageReview = sendedReview && submitResponse.getReview()?.getContent()?.getValue();
  const reviewPrompt = sendedReview && submitResponse?.getReviewPrompt();
  const reviewPromptType = reviewPrompt && reviewPrompt.getType();
  const isGoogleReviewPrompt = sendedReview && reviewPromptType === reviewPromptTypes.google;
  const isTrustpilotReviewPrompt = sendedReview && reviewPromptType === reviewPromptTypes.trustpilot;
  const googleReviewButtonFeature = useFeature(GrowthBookFeatures.GOOGLE_REVIEW_BUTTON).value || false;
  const trustpilotReviewButtonFeature = useFeature(GrowthBookFeatures.TRUSTPILOT_REVIEW_BUTTON).value || false;
  const isGoodReview = sendedReview && sendedReview?.getTotalScore() >= 4;
  const isShowGoogleReviewButton = googleReviewButtonFeature && isGoodReview && isGoogleReviewPrompt;
  const isShowTrustpilotButton = trustpilotReviewButtonFeature && isGoodReview && isTrustpilotReviewPrompt;
  const reviewPromptLink = reviewPrompt && reviewPrompt.getUrl();

  const handlerClickButton = () => {
    closeAppByMessage();
  };

  const handlerRedirectToReviewSite = (url: string | null | undefined, message: string | null | undefined) => {
    function redirectToSite(url: string) {
      window.open(url, '_blank', 'noreferrer');
      if (reviewPromptType === reviewPromptTypes.google) RudderAnalyticsService.trackTransitToGoogleReview(url);
      if (reviewPromptType === reviewPromptTypes.trustpilot) RudderAnalyticsService.trackTransitToTrustpilotReview(url);
    }

    if (message && url) {
      copy(message).then(() => {
        redirectToSite(url);
      });
    }

    if (!message && url) {
      redirectToSite(url);
    }
  };

  return errorObject ? (
    <ErrorScreen onClick={navigateToPreviousPage} errorObject={errorObject} />
  ) : (
    <StyledConfirmationScreen>
      {!noProgress && <ScreenHeader progress={progress} screenIndex={screenIndex} screensLength={screensLength} />}
      <ContentContainer>
        <ScrollContainer id="scroll-container">
          <StyledForm>
            {formTitle && <Title>{formTitle}</Title>}
            {theme.isMobile && (
              <Illustration>
                <IconToothSmileSidebar width={200} height={200} />
              </Illustration>
            )}
            {formDescription && typeof formDescription === 'object' ? (
              formDescription.map((item, index) => <Description isReviewBoostText={(isShowGoogleReviewButton || isShowTrustpilotButton)} key={index}>{item}</Description>)
            ) : (
              <>
                <Description isReviewBoostText={(isShowGoogleReviewButton || isShowTrustpilotButton)}>{formDescription}</Description>
                {formPrescription && (isShowGoogleReviewButton || isShowTrustpilotButton) && <Description isReviewBoostText={(isShowGoogleReviewButton || isShowTrustpilotButton)}>{formPrescription}</Description>}
              </>
            )}
            {isShowGoogleReviewButton && (
              <GoogleButton
                onClick={() => handlerRedirectToReviewSite(reviewPromptLink, messageReview)}
                text={t('chapter1.screen4.reviewPromptButtonText')}
              />
            )}
            {isShowTrustpilotButton
              && (
              <TruspilotButton
                onClick={() => handlerRedirectToReviewSite(reviewPromptLink, messageReview)}
                text={t('chapter1.screen4.reviewPromptButtonText')}
              />
              )}
          </StyledForm>
          {hasWidgetConfig && (
            <ButtonContainer>{btnConfig && <Button onClick={handlerClickButton}>{btnConfig.children}</Button>}</ButtonContainer>
          )}
        </ScrollContainer>
      </ContentContainer>
    </StyledConfirmationScreen>
  );
};

ConfirmationScreen.defaultProps = {
  noProgress: false,
  btnConfig: {},
  formTitle: undefined,
  formDescription: undefined,
  formPrescription: undefined,
};

interface ThemedStyledProps extends Pick<DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>, 'key' | keyof HTMLAttributes<HTMLParagraphElement>> {
  isReviewBoostText?: boolean;
}

const ScrollContainer = styled.div`
  max-height: 100%;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 7px;
    background: ${(props) => props.theme.color.background};
    border-radius: 25px;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.color.disabledColor};
    border-radius: 25px;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow-y: hidden;
  background: ${(props) => props.theme.color.background};

  @media ${(props) => props.theme.breakpoints.tablet} {
    background: ${(props) => props.theme.color.additional};
  }
`;

const Description = styled.p<ThemedStyledProps>`
  font-size: ${(props) => props.theme.typography.size.breadM};
  font-weight: ${(props) => props.theme.typography.weight.regular};
  line-height: ${(props) => props.theme.typography.height.normal};
  line-height: 24px;
  color: ${(props) => props.theme.color.textColor};
  text-align: ${((props) => (props.isReviewBoostText ? 'left' : 'center'))};
`;

const StyledForm = styled.div`
  border-radius: 10px;
  padding: 10px 0px;
  box-sizing: border-box;
  box-shadow: none;
  background: ${(props) => props.theme.color.background};
  padding: ${(props) => {
    if (props.theme.isMobile) {
      return '32px 20px';
    }
    return '0px 24px 15px 24px';
  }};
  @media ${(props) => props.theme.isMobile} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const ButtonContainer = styled.div`
  margin-top: auto;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.typography.size.heading1};
  font-weight: ${(props) => props.theme.typography.weight.bold};
  color: ${(props) => props.theme.color.textColor};
  margin-bottom: 15px;
  line-height: 30px;
  position: relative;
  text-align: center;
`;

const Illustration = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 230px;
  margin: 15px 0 0;
  ${(props) => css`
    .no-fill-color {
      fill: ${(props) => mix(0.2, props.theme.color.accentColor, '#fff')};
    }
    .fill-color {
      fill: ${(props) => props.theme.color.accentColor};
    }
  `};
`;

const StyledConfirmationScreen = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.fade-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  &.fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`;
