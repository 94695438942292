import React, { FC, ReactNode, useState } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { theme } from '../../theme';
import useEventListener from '../../hooks/useEventListener';
import { Message, MessageType, ReviewConfig } from '../../types/Models';

type AppThemeProviderProps = {
  children: ReactNode | ReactNode[];
};

const AppThemeProvider: FC<AppThemeProviderProps> = function (props) {
  const { children } = props;
  const [appTheme, setAppTheme] = useState(theme);

  useEventListener<Message<ReviewConfig>>('message', (event) => {
    const { data } = event;

    if (data && data.type === MessageType.UPDATE_CONFIG) {
      setAppTheme((state) => {
        if (!data.payload) return state;

        return {
          ...state,
          color: {
            ...state.color,
            accentColor: data?.payload.accentColor,
            background: data?.payload.backgroundColor,
            textColor: data?.payload.textColor,
            disabledColor: data?.payload.disabledColor,
          },
        };
      });
      window.name = JSON.stringify(data?.payload);
    }
  });

  return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};

export default AppThemeProvider;
