import React, {
  MouseEventHandler, ReactElement, useContext, useRef,
} from 'react';
import styled from 'styled-components/macro';
import { Button } from '../Button/Button';
import { ScreenHeader } from '../ScreenHeader/ScreenHeader';
import { ButtonConfig, ScreenType, TypeID } from '../../types/Models';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { ConfigContext } from '../../config';
import { Loader } from '../Loader/Loader';

interface ButtonTypes extends ButtonConfig {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
  isLoading: boolean;
}

export interface ScreenProps {
  progress: number;
  btnConfig: ButtonTypes;
  children: ReactElement | string;
  hideButton?: boolean;
  noProgress?: boolean;
  type: ScreenType;
  screenIndex: number;
  screensLength: number;
  onBackClick: MouseEventHandler<HTMLButtonElement>;
  id?: TypeID | undefined;
}

interface ContentContainerProps {
  type: ScreenType;
}

export const Screen: React.FC<ScreenProps> = function (props) {
  const {
    id,
    progress,
    btnConfig,
    children,
    hideButton,
    noProgress,
    type,
    screenIndex,
    screensLength,
    onBackClick,
  } = props;

  const {
    showConfirmModal, setShowConfirmModal, chapters, currentChapter, currentScreen, screenLoader,
  } = useContext(ConfigContext);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const confirmScreen = chapters[currentChapter].screens[currentScreen]?.confirmScreen;

  const handlerCloseConfirmationModal = () => {
    setShowConfirmModal(false);
  };

  return (
    <StyledScreen data-test="screen">
      <Loader enabled={screenLoader}>
        <StyledScreen>
          {!noProgress && (
          <ScreenHeader
            progress={progress}
            screenIndex={screenIndex}
            screensLength={screensLength}
            onBackClick={onBackClick}
          />
          )}
          <ContentContainer type={type}>
            <ScrollContainer id="scroll-container">
              {children}
              {!hideButton && (
                <MobileButtonContainer data-testid="mobilebtnContainer">
                  <Button buttonRef={buttonRef} {...btnConfig} id={id} />
                </MobileButtonContainer>
              )}
            </ScrollContainer>
          </ContentContainer>

          {!hideButton && (
            <DesktopButtonContainer type={type} data-testid="desktopbtnContainer">
              <Button buttonRef={buttonRef} {...btnConfig} id={id} />
            </DesktopButtonContainer>
          )}
          {confirmScreen && showConfirmModal && (
            <ConfirmationModal
              onAccept={btnConfig.onClick}
              onReject={handlerCloseConfirmationModal}
              content={confirmScreen}
            />
          )}

        </StyledScreen>
      </Loader>
    </StyledScreen>
  );
};

Screen.defaultProps = {
  hideButton: false,
  noProgress: false,
  id: undefined,
};

const StyledScreen = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.fade-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  &.fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`;

const ContentContainer = styled.div<ContentContainerProps>`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow-y: hidden;
  background: ${(props) => {
    if (props.type === 'authorization' && props.theme.isMobile) {
      return '#FFF5DE';
    }

    return props.theme.color.background;
  }};

  @media ${(props) => props.theme.breakpoints.tablet} {
    background-color: ${(props) => {
    if (props.type === 'authorization') {
      return '#FFF5DE';
    }
    return props.theme.color.additional;
  }};
  }
`;

const ScrollContainer = styled.div`
  max-height: 100%;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 7px;
    background: ${(props) => props.theme.color.background};
    border-radius: 25px;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.color.disabledColor};
    border-radius: 25px;
  }
`;

const MobileButtonContainer = styled.div`
  display: none;
  margin-top: auto;

  @media ${(props) => props.theme.breakpoints.tablet} {
    display: block;
  }
`;

const DesktopButtonContainer = styled.div<ContentContainerProps>`
  padding: 24px;
  border-top: 1px solid ${(props) => props.theme.color.additional};
  background: ${(props) => {
    if (props.theme.isMobile) {
      return props.theme.color.disabledColor;
    }
    return props.theme.color.background;
  }};
  @media ${(props) => props.theme.breakpoints.tablet} {
    display: none;
  }
`;
