import React from 'react';
import styled from 'styled-components/macro';

export interface TextAreaProps {
  title: string | undefined;
  value: string | undefined;
  onChange: Function;
  onBlur: React.FocusEventHandler<HTMLTextAreaElement> | undefined;
  onFocus: React.FocusEventHandler<HTMLTextAreaElement> | undefined;
  placeholder: string | undefined;
  testId: string;
}

export const TextArea: React.FC<TextAreaProps> = function (props) {
  const {
    title, value, onChange, onBlur, onFocus, placeholder, testId,
  } = props;

  return (
    <Container>
      {title && <Title>{title}</Title>}
      <StyledTextArea
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        data-testid={testId}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTextArea = styled.textarea`
  height: 180px;
  width: 100%;
  border: 2px solid ${(props) => props.theme.color.disabledColor};
  color: ${(props) => props.theme.color.textColor};
  font-size: ${(props) => props.theme.typography.size.breadM};
  border-radius: 10px;
  padding: 12px;
  box-sizing: border-box;
  resize: none;
  outline: none;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.color.disabledColor};
  }
  :-ms-input-placeholder {
    color: ${(props) => props.theme.color.disabledColor};
  }
`;

const Title = styled.p`
  font-size: ${(props) => props.theme.typography.size.breadM};
  color: ${(props) => props.theme.color.textColor};
  margin: 0 0 16px;
`;
