import React, { useContext } from 'react';
import styled from 'styled-components/macro';

import { Button } from '../../components/Button/Button';
import { ConfigContext } from '../../config';
import { ErrorHandling } from '../../types/Models';

export type ErrorScreenProps = {
  onClick?: Function;
  errorObject: ErrorHandling;
};

export const ErrorScreen: React.FC<ErrorScreenProps> = function (props) {
  const { onClick, errorObject } = props;
  const { setErrorObject, setErrorCode } = useContext(ConfigContext);
  const { title, message, buttonText } = errorObject;

  const clearErrors = () => {
    setErrorCode('');
    setErrorObject(null);
  };

  // Handle click events sent by props
  const handlePropOnClick = () => {
    if (onClick) {
      onClick();
    }

    // add some delay to avoid success screen being shown
    setTimeout(() => clearErrors(), 500);
  };

  const handleButtonClick = () => (onClick ? handlePropOnClick() : clearErrors());

  return (
    <StyledScreen data-test="error-screen">
      <TextContainer>
        <Title>{title}</Title>
        <Text>{message}</Text>
      </TextContainer>

      <ButtonContainer>{buttonText && <Button type="default" onClick={handleButtonClick}>{buttonText}</Button>}</ButtonContainer>
    </StyledScreen>
  );
};

ErrorScreen.defaultProps = {
  onClick: () => {},
};

const Text = styled.p`
  color: ${(props) => props.theme.color.textColor};
  font-size: ${(props) => props.theme.typography.size.breadM};
  line-height: 20px;
  text-align: center;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.color.textColor};
  font-weight: ${(props) => props.theme.typography.weight.bold};
  font-size: ${(props) => props.theme.typography.size.heading1};
  line-height: 30px;
  margin-bottom: 24px;
  text-align: center;
`;

const StyledScreen = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.fade-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  &.fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  padding: 0 45px;
  background: ${(props) => props.theme.color.background};
`;

const ButtonContainer = styled.div`
  padding: 24px;
  border-top: 1px solid ${(props) => props.theme.color.additional};
  background: ${(props) => {
    if (props.theme.isMobile) {
      return props.theme.color.disabledColor;
    }
    return props.theme.color.background;
  }};
`;
