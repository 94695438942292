import React, {
  JSXElementConstructor, ReactElement, useEffect,
} from 'react';
import RudderAnalyticsService from '../../service/RudderAnalyticsService';
import { sendingEventByFlow } from '../../helpers/sendingEventByFlow';

interface RudderstackProviderProps {
  children: ReactElement<any, string | JSXElementConstructor<any>> | string;
}

export const RudderstackProvider:React.FC<RudderstackProviderProps> = function (props) {
  const { children } = props;

  useEffect(() => {
    RudderAnalyticsService.initialize();
    sendingEventByFlow();
  }, []);

  return (
    <>
      {' '}
      {children}
    </>
  );
};
