import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import { DoneStep } from '../DoneStep/DoneStep';

export interface DoneStepListProps {
  steps: { title: string }[];
  withBackground?: boolean;
}

export const DoneStepList: React.FC<DoneStepListProps> = function (props) {
  const { steps, withBackground } = props;

  return (
    <StyledDoneStepList>
      {steps.map((step, index) => (
        <Fragment key={index}>
          {index === 0 && <Spacer />}
          <DoneStep title={step.title} withBackground={withBackground} />
          {index < steps.length - 1 && <Spacer />}
        </Fragment>
      ))}
    </StyledDoneStepList>
  );
};

DoneStepList.defaultProps = {
  withBackground: false,
};

const StyledDoneStepList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px;
`;

const Spacer = styled.span`
  height: 15px;
`;
