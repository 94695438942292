import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as IconDone } from '../../assets/images/icon-done.svg';

export interface DoneStepProps {
  title: string;
  withBackground?: boolean;
}

interface StyledDoneStepProps {
  withBackground?: boolean;
}

export const DoneStep: React.FC<DoneStepProps> = function (props) {
  const { title, withBackground } = props;
  return (
    <StyledDoneStep withBackground={withBackground}>
      <IconDone height={30} width={30} />
      <Title>{title}</Title>
    </StyledDoneStep>
  );
};

DoneStep.defaultProps = {
  withBackground: false,
};

const StyledDoneStep = styled.div<StyledDoneStepProps>`
  display: flex;
  align-items: center;

  ${(props) => css`
    .fill-color {
      fill: ${(props) => props.theme.color.textColor};
    }
  `};

  ${(props) => props.withBackground
    && css`
      background: ${props.theme.color.additional};
      padding: 18px 24px;
      border-radius: 20px;
    `}
`;

const Title = styled.h3`
  color: ${(props) => props.theme.color.textColor};
  font-size: ${(props) => props.theme.typography.size.heading3};
  font-weight: ${(props) => props.theme.typography.weight.bold};
  margin-left: 13px;
`;
