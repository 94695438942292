export interface AquaCityClinis {
  id: string;
  city: string;
  active: boolean,
  clinics: {
    clinic: string;
    id: string;
    address: string;
  }[];
}

export const aquaClinics: AquaCityClinis[] = [
  {
    id: '1',
    city: 'Stockholm',
    active: false,
    clinics: [
      {
        clinic: 'Aqua Dental Express',
        id: '5916',
        address: 'Vasagatan 12, 111 20 Stockholm',
      },
      {
        clinic: 'Aqua Dental Farsta',
        id: '6219',
        address: 'Farsta Torg 25, 123 47 Stockholm',
      },
      {
        clinic: 'Aqua Dental Hammarby Sjöstad',
        id: '67',
        address: 'Lugnets Allé 10, 120 66 Stockholm',
      },
      {
        clinic: 'Aqua Dental Kista',
        id: '2417',
        address: 'Danmarksgatan 46, 164 40 Kista',
      },
      {
        clinic: 'Aqua Dental Kungsholmen',
        id: '2958',
        address: 'Norr Mälarstrand 62, 112 35 Stockholm',
      },
      {
        clinic: 'Aqua Dental Mörby',
        id: '2893',
        address: 'Mörby Centrum, 182 31 Danderyd',
      },
      {
        clinic: 'Aqua Dental Nacka',
        id: '5187',
        address: 'Forumvägen 32, 131 53 Nacka',
      },
      {
        clinic: 'Aqua Dental Odenplan',
        id: '3340',
        address: 'Dalagatan 42, 113 24 Stockholm',
      },
      {
        clinic: 'Aqua Dental Sollentuna',
        id: '5171',
        address: 'Sollentunavägen 163C, 191 47 Sollentuna',
      },
      {
        clinic: 'Aqua Dental Stureplan',
        id: '6130',
        address: 'Riddargatan 12 A, 114 35 Stockholm',
      },
      {
        clinic: 'Aqua Dental Täby',
        id: '6044',
        address: 'Täby centrum, 183 34 Täby',
      },
      {
        clinic: 'Aqua Dental Vasastan',
        id: '6021',
        address: 'Saltmätargatan 22, 113 59 Stockholm',
      },
      {
        clinic: 'Aqua Dental Östermalm',
        id: '2959',
        address: 'Sturegatan 48, 114 36 Stockholm',
      },
    ],
  },
  {
    id: '2',
    city: 'Göteborg',
    active: false,
    clinics: [
      {
        clinic: 'Aqua Dental Olskroken',
        id: '3749',
        address: 'Redbergsvägen 13A, 416 65 Göteborg',
      },
      {
        clinic: 'Aqua Dental Östra Hamngatan',
        id: '5765',
        address: 'Östra Hamngatan 52, 411 09 Göteborg',
      },
    ],
  },
  {
    id: '3',
    city: 'Malmö',
    active: false,
    clinics: [
      {
        clinic: 'Aqua Dental Malmö',
        id: '6212',
        address: 'Sdra Promenaden 69A, 211 38 Malmö',
      },
    ],
  },
  {
    id: '4',
    city: 'Uppsala',
    active: false,
    clinics: [
      {
        clinic: 'Aqua Dental Uppsala',
        id: '6213',
        address: 'Vaksalagatan 3, 753 29 Uppsala',
      },
    ],
  },
  {
    id: '5',
    city: 'Västerås',
    active: false,
    clinics: [
      {
        clinic: 'Aqua Dental Västerås',
        id: '6249',
        address: 'Vasagatan 25, 722 15 Västerås',
      },
    ],
  },
  {
    id: '6',
    city: 'Helsingborg',
    active: false,
    clinics: [
      {
        clinic: 'Aqua Dental Helsingborg',
        id: '6255',
        address: 'Kullagatan, 252 20 Helsingborg',
      },
    ],
  },
  {
    id: '7',
    city: 'Åre',
    active: false,
    clinics: [
      {
        clinic: 'Aqua Dental Åre',
        id: '6306',
        address: 'Sankt Olavs väg 33, 837 52 Åre',
      },
    ],
  },
  {
    id: '8',
    city: 'Östersund',
    active: false,
    clinics: [
      {
        clinic: 'Aqua Dental Östersund',
        id: '6307',
        address: 'Kyrkgatan 66, 831 34 Östersund',
      },
    ],
  },
  {
    id: '9',
    city: 'Karlstad',
    active: false,
    clinics: [
      {
        clinic: 'Aqua Dental Karlstad',
        id: '6274',
        address: 'Järnvägsgatan 7, 652 25 Karlstad',
      },
    ],
  },
];
