import React, { useMemo, ReactElement } from 'react';
import styled from 'styled-components';
import { ReactComponent as AquaDental } from '../../assets/images/logos/aqua-dental.svg';

const logoObject: { [key: number]: ReactElement } = {
  120: <AquaDental />,
};

export const Header: React.FC = function () {
  return (
    <HeaderWrapper>
      <AquaDental />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  min-width: 100%;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.color.background};
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: ${(props) => props.theme.color.disabledColor};

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 50px;
    margin-bottom: 24px;
  }
`;
