import { mix } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as PlusIcon } from '../../assets/images/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../assets/images/minus-icon.svg';
import { ReactComponent as AngleRightIcon } from '../../assets/images/angle-right-icon.svg';

interface SelectRowProps {
  onClickRow?: (id: string) => void;
  onClickTitleRow?: (id: string) => void;
  data: {
    city: string;
    id: string;
    active: boolean
    clinics: {
      clinic: string;
      id: string;
      address: string;
    }[]
  };
}

export const SelectRow: React.FC<SelectRowProps> = function (props) {
  const { data, onClickRow, onClickTitleRow } = props;

  const handlerClickHeaderRow = (id: string) => {
    if (onClickTitleRow) {
      onClickTitleRow(id);
    }
  };

  const handlerClickTitleRow = (id: string) => {
    if (onClickRow) {
      onClickRow(id);
    }
  };

  return (
    <>
      <SelectRowStyle onClick={() => handlerClickHeaderRow(data.id)} className={data.active ? 'active' : ''}>
        <HeaderRow active={data.active} data-testid={data.city}>
          <div>{data.city}</div>
          {' '}
          {data.active
            ? <MinusIcon width={16} />
            : <PlusIcon width={20} height={20} />}
        </HeaderRow>
      </SelectRowStyle>
      <RowChildrens active={data.active}>
        {data.clinics.map((clinic) => (
          <RowChildren key={clinic.id} onClick={() => handlerClickTitleRow(clinic.id)}>
            <div data-testid={clinic.clinic}>
              <ChildrenTitle>{clinic.clinic}</ChildrenTitle>
              <ChildrenDescription>{clinic.address}</ChildrenDescription>
            </div>
            <AngleRightIcon width={12} />
          </RowChildren>
        ))}
      </RowChildrens>
    </>
  );
};

SelectRow.defaultProps = {
  onClickRow: () => {},
  onClickTitleRow: () => {},
};

const SelectRowStyle = styled.div`
  padding: 15px 15px;
  margin: 0 -15px;
  cursor: pointer;
  border-bottom: 1px solid #c1c1c1;
  transition: 0.2s ease-in-out;
  font-weight: ${(props) => props.theme.typography.weight.medium};
  ${() => css`
    &:hover, &.active {
      background: ${(props) => mix(0.2, props.theme.color.accentColor, '#fff')};
    }
  `};
`;

const HeaderRow = styled.div<{active: boolean}>`
  display: flex;
  justify-content: space-between;
`;

const RowChildrens = styled.div<{active: boolean}>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  padding: 0 15px;
  cursor: pointer;
  border-bottom: 1px solid #c1c1c1;
  transition: 0.2s ease-in-out;

`;

const RowChildren = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #F2F4F7;
  padding: 10px 0;
`;

const ChildrenTitle = styled.div`
  font-weight: ${(props) => props.theme.typography.weight.medium};
  margin-bottom: 5px;
`;

const ChildrenDescription = styled.div`
    font-size: 12px;
    color: #666666;
`;
