import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import { theme } from '../../theme';
import { Button } from '../Button/Button';

interface IContent {
  title: string;
  content: string;
}

export interface ConfirmationModalProps {
  onAccept: Function;
  onReject: Function;
  content: IContent;
}
export const ConfirmationModal: React.FC<ConfirmationModalProps> = function (props) {
  const { onAccept, onReject, content: modalData } = props;
  const { t } = useTranslation();

  const handlerClickAcceptButton = () => {
    if (onAccept) {
      onAccept();
    }
  };
  const handlerClickRejectButton = () => {
    if (onAccept) {
      onReject();
    }
  };

  return (
    <>
      <ModalOverlay />
      <ModalWrap>
        <Header>
          <HeaderIcon onClick={handlerClickRejectButton}>
            <CloseIcon width={18} height={18} fill={theme.color.textColor} />
          </HeaderIcon>
          {modalData?.title && <HeaderText>{modalData.title}</HeaderText>}
        </Header>
        <ModalContent>
          {modalData?.content && <Content data-testid="reviewText">{modalData.content}</Content>}
          <ButtonWrap>
            <ButtonRow>
              <Button onClick={handlerClickAcceptButton} type="submit" testId="acceptReviewBtn">
                {t('generals.confirmationModal.buttonAccept')}
              </Button>
            </ButtonRow>
            <Button onClick={handlerClickRejectButton} type="cancel" testId="declineReviewBtn">
              {t('generals.confirmationModal.buttonReject')}
            </Button>
          </ButtonWrap>
        </ModalContent>
      </ModalWrap>
    </>
  );
};

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 53, 77, 0.7);
  z-index: 1;
`;

const ModalWrap = styled.div`
  border-radius: 20px 20px 0 0;
  padding: 16px 16px;
  background: ${(props) => props.theme.color.background};
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: -webkit-fill-available;
`;

const Header = styled.h3`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const HeaderText = styled.span`
  color: ${(props) => props.theme.color.darkBlue};
  flex: 1 1 auto;
  font-size: ${(props) => props.theme.typography.size.button};
  font-weight: ${(props) => props.theme.typography.weight.bold};
  text-align: left;
  margin-bottom: 16px;
`;

const HeaderIcon = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: auto;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.pre`
  font-family: inherit;
  font-size: ${(props) => props.theme.typography.size.button};
  font-weight: ${(props) => props.theme.typography.weight.regular};
  margin-bottom: 30px;
  padding: 15px 10px;
  border-radius: 15px;
  background-color: #f6f6f6;
`;

const ButtonRow = styled.div`
  margin-bottom: 16px;
`;

const ButtonWrap = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
`;
