import { TFunctionKeys, TOptions } from 'i18next';

export enum LANGUAGE {
  SV = 'sv',
  EN = 'en'
}

export const DEFAULT_LANG = LANGUAGE.SV;

export const ACCEPTED_LANGUGES: { [key: string]: string } = {
  EN: LANGUAGE.EN,
  SV: LANGUAGE.SV,
};

export type AcceptedLangugesTypes = typeof ACCEPTED_LANGUGES;

export interface II18NService {
  changeLanguage(lang: string): Promise<void>
  onLoadedLanguage(): Promise<void>
  getCurrentLanguage(): Promise<string>
  onLanguageChanged(callback: (language: string) => void): void
  getTranslation(keys: TFunctionKeys | TFunctionKeys[], options?: TOptions): string
}
