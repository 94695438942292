import 'normalize.css';
import { createGlobalStyle } from 'styled-components/macro';

import MetropolisRegularTTF from './assets/fonts/Metropolis-Regular.ttf';
import MetropolisMediumTTF from './assets/fonts/Metropolis-Medium.ttf';
import MetropolisSemiBoldTTF from './assets/fonts/Metropolis-SemiBold.ttf';
import MetropolisBoldTTF from './assets/fonts/Metropolis-Bold.ttf';
import MetropolisRegularWOFF from './assets/fonts/Metropolis-Regular.woff';
import MetropolisMediumWOFF from './assets/fonts/Metropolis-Medium.woff';
import MetropolisSemiBoldWOFF from './assets/fonts/Metropolis-SemiBold.woff';
import MetropolisBoldWOFF from './assets/fonts/Metropolis-Bold.woff';
import MetropolisRegularWOFF2 from './assets/fonts/Metropolis-Regular.woff2';
import MetropolisMediumWOFF2 from './assets/fonts/Metropolis-Medium.woff2';
import MetropolisSemiBoldWOFF2 from './assets/fonts/Metropolis-SemiBold.woff2';
import MetropolisBoldWOFF2 from './assets/fonts/Metropolis-Bold.woff2';
import { theme } from './theme';

export type ThemeType = typeof theme

export const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  @font-face {
    font-family: "Metropolis";
    src: url(${MetropolisRegularWOFF2}) format("woff2"),
      url(${MetropolisRegularWOFF}) format("woff"),
      url(${MetropolisRegularTTF}) format("truetype");
    font-weight: 400;
  }

  @font-face {
    font-family: "Metropolis";
    src: url(${MetropolisMediumWOFF2}) format("woff2"),
      url(${MetropolisMediumWOFF}) format("woff"),
      url(${MetropolisMediumTTF}) format("truetype");
    font-weight: 500;
  }

  @font-face {
    font-family: "Metropolis";
    src: url(${MetropolisSemiBoldWOFF2}) format("woff2"),
      url(${MetropolisSemiBoldWOFF}) format("woff"),
      url(${MetropolisSemiBoldTTF}) format("truetype");
    font-weight: 600;
  }

  @font-face {
    font-family: "Metropolis";
    src: url(${MetropolisBoldWOFF2}) format("woff2"),
      url(${MetropolisBoldWOFF}) format("woff"),
      url(${MetropolisBoldTTF}) format("truetype");
    font-weight: 700;
  }

  body {
    font-family: ${(props) => props.theme.typography.fonts.base};
    color: ${(props) => props.theme.color.textColor};
    background: ${(props) => props.theme.color.additional};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    min-height: 100vh;
    min-width: 100vw;

    * {
      margin: 0;
      padding: 0;
    }
    
    #root {
      width: 100%
    }

    @media ${(props) => props.theme.breakpoints.tablet} {
      min-height: 100%;
      overflow: hidden;
    }
  }
`;
