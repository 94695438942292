/* eslint-disable no-param-reassign */
import { getReviewIDFromURL } from '../helpers/getReviewIDFromURL';
import { getWidgetConfig } from '../helpers/getWidgetConfig';
import { ConfigChapter } from '../types/Models';
import { SMS_FLOW_DISABLE_STEPS, WIDGET_FLOW_DISABLE_STEPS } from '../constants';

const [_, hasWidgetConfig] = getWidgetConfig();
const [reviewID] = getReviewIDFromURL();

export const filteringScreens = (steps: any[]) => {
  if (reviewID) {
    return steps.filter((item) => !SMS_FLOW_DISABLE_STEPS.includes(item.id));
  }
  if (hasWidgetConfig) {
    return steps.filter((item) => !WIDGET_FLOW_DISABLE_STEPS.includes(item.id));
  }
  return steps;
};

export const calculateScreenLength = (chapters: ConfigChapter[], chapterNumber: number) => {
  if (chapterNumber === undefined) return 0;
  if (hasWidgetConfig) {
    return chapters[chapterNumber].screens.length - 1;
  }
  return chapters[chapterNumber].screens.length;
};
