/* eslint-disable no-nested-ternary */
import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Answer } from '../Answer/Answer';
import { AnswerData } from '../../types/Models';
import I18NService from '../../service/I18NService';

export interface AnswerListProps {
  answers: AnswerData[];
  canSelectMultiple?: boolean;
  onChange: Function;
}

export const AnswerList: React.FC<AnswerListProps> = function (props) {
  const { answers, canSelectMultiple, onChange } = props;
  const locale = I18NService.currentLocale;

  const onAnswerChange = (changedAnswer: AnswerData) => onChange(
    answers.map((answer) => ({
      ...(answer.id === changedAnswer.id
        ? changedAnswer
        : {
          ...answer,
          selected:
                canSelectMultiple && !changedAnswer.cancelsOthers
                  ? answer.cancelsOthers
                    ? false
                    : answer.selected
                  : answer.additional
                    ? answer.selected
                    : false,
        }),
    })),
  );

  const answersSorting = useMemo(() => {
    const newAnswers = [...answers];
    const noSorting = newAnswers.filter((answer) => answer?.noSorting);

    const clearData = newAnswers.filter((answer) => !answer.noSorting).sort((a, b) => {
      const byName = a.text.localeCompare(b.text, locale);
      return byName;
    }).concat(noSorting);

    return clearData;
  }, [answers]);

  const additionalsSorting = useMemo(() => {
    const newAnswers = [...answers];
    const clearData = newAnswers.filter((answer) => answer.additional).sort((a, b) => a.text.localeCompare(b.text, locale));

    return clearData;
  }, [answers]);

  return (
    <StyledAnswerList data-test="answer-list">
      {additionalsSorting.map((answer: AnswerData, index: number) => (
        <Fragment key={index}>
          <Answer answer={answer} onChange={onAnswerChange} />
          {(index < additionalsSorting.length - 1 || answer.type === 'check') && <Separator />}
        </Fragment>
      ))}
      {answersSorting.map((answer: AnswerData, index: number) => {
        if (!answer.additional) {
          return (
            <Fragment key={index}>
              <Answer answer={answer} onChange={onAnswerChange} />
              {(index < additionalsSorting.length - 1 || answer.type === 'check') && <Separator />}
            </Fragment>
          );
        }
        return null;
      })}
    </StyledAnswerList>
  );
};

AnswerList.defaultProps = {
  canSelectMultiple: false,
};

const StyledAnswerList = styled.div``;

const Separator = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.color.disabledColor};
  margin: 0;
`;
