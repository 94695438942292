import { useState, useEffect } from 'react';
import { ErrorHandling } from '../types/Models';
import i18NService from '../service/I18NService';

// Custom hook for handling errors
export const useErrorHandling = (err: string) => {
  const [errorObject, setErrorObject] = useState<ErrorHandling | null>(null);

  useEffect(() => {
    const getErrors = (error: string) => {
      switch (error) {
        case 'SUBMISSION_FAILED':
          setErrorObject({
            title: i18NService.getTranslation('errors:SUBMISSION_FAILED.title'),
            message: i18NService.getTranslation('errors:SUBMISSION_FAILED.message'),
            buttonText: i18NService.getTranslation('errors:SUBMISSION_FAILED.buttonText'),
          });
          break;
        case 'ALREADY_COMPLETED':
          setErrorObject({
            title: i18NService.getTranslation('errors:ALREADY_COMPLETED.title'),
            message: i18NService.getTranslation('errors:ALREADY_COMPLETED.message'),
          });
          break;
        case 'NO_SUCH_BOOKING':
          setErrorObject({
            title: i18NService.getTranslation('errors:NO_SUCH_BOOKING.title'),
            message: i18NService.getTranslation('errors:NO_SUCH_BOOKING.message'),
            buttonText: i18NService.getTranslation('errors:NO_SUCH_BOOKING.buttonText'),
          });
          break;
        case 'LOST_INTERNET_CONNECTION':
          setErrorObject({
            title: i18NService.getTranslation('errors:LOST_INTERNET_CONNECTION.title'),
            message: i18NService.getTranslation('errors:LOST_INTERNET_CONNECTION.message'),
            buttonText: i18NService.getTranslation('errors:LOST_INTERNET_CONNECTION.buttonText'),
          });
          break;
        default:
          break;
      }
    };

    if (err) {
      getErrors(err);
    }

    return () => setErrorObject(null);
  }, [err]);

  return [errorObject, setErrorObject];
};
