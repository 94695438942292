import React, { useContext } from 'react';
import styled, { css } from 'styled-components/macro';
import { mix } from 'polished';
import { StepList } from '../../components/StepList/StepList';
import { ConfigContext, overviewData } from '../../config';
import { ReactComponent as IconToothSidebar } from '../../assets/images/icon-tooth-sidebar.svg';
import { ReactComponent as IconToothSmileSidebar } from '../../assets/images/icon-tooth-smile-sidebar.svg';
import { Header } from '../../components/Header/Header';
import { theme } from '../../theme';
import { LanguageSwitcher } from '../../components/LanguageSwitcher/LanguageSwitcher';
import { ACCEPTED_LANGUGES } from '../../service/interfaces/II18NService';

export interface OverviewScreenProps {
  onStepClick: Function;
  currentChapter: number;
}

export const OverviewScreen: React.FC<OverviewScreenProps> = function (props) {
  const { onStepClick, currentChapter } = props;
  const { currentScreen, chapters } = useContext(ConfigContext);
  const { title, description, steps } = overviewData().overviews[currentChapter];
  const showLangSwitcher = currentScreen === 0 && theme.isMobile;

  return (
    <StyledOverviewScreen>
      {theme.isMobile && <Header />}
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
      {chapters[0].screens[currentScreen].id === 'SUCCESS' ? (
        <Illustration>
          <IconToothSmileSidebar width={300} height={250} />
        </Illustration>
      ) : (
        <Illustration>
          <IconToothSidebar width={300} height={250} />
        </Illustration>
      )}
      {steps && <StepList steps={steps} onStepClick={onStepClick} />}
      {showLangSwitcher && (
        <LanguageSwitcherWrapper>
          <LanguageSwitcher languges={ACCEPTED_LANGUGES} />
        </LanguageSwitcherWrapper>
      )}
    </StyledOverviewScreen>
  );
};

const StyledOverviewScreen = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  height: 100%;
  box-sizing: border-box;
  background: ${(props) => props.theme.color.background};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 0 0;
    min-height: 100%;
    height: auto;
  }

  &.fade-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  &.fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`;

const TextContainer = styled.div`
  padding: 0 24px;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.color.textColor};
  font-weight: ${(props) => props.theme.typography.weight.bold};
  font-size: ${(props) => props.theme.typography.size.heading1};
  line-height: 30px;
  margin-bottom: 12px;
`;

const Description = styled.h4`
  color: ${(props) => props.theme.color.textColor};
  font-weight: ${(props) => props.theme.typography.weight.medium};
  line-height: 1.5;
  font-size: ${(props) => props.theme.typography.size.heading4};
`;

const Illustration = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  flex: auto;
  padding: 11px 0;
  margin: 15px 0 0;
  ${() => css`
    .no-fill-color {
      fill: ${(props) => mix(0.2, props.theme.color.accentColor, '#fff')};
    }
    .fill-color {
      fill: ${(props) => props.theme.color.accentColor};
    }
  `};
`;

const LanguageSwitcherWrapper = styled.div`
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;
